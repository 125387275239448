#slidebox {
    max-width: 400px;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    z-index: 10;
    border-top: 1px solid #dee4ed;
    border-left: 1px solid #dee4ed;
    border-top-left-radius: 8px;
    animation: 5s slide-up;
    height: 340px;


}
.icon-slidebox{
    position: fixed;
    bottom: 20px;
    right: 5px;
    width: 60px;
    height: 60px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    z-index: 10;
}
#slidebox.show-box {
    right: 0;
    border-radius: 50px 0px 0px 0;
    border: 1px solid #dee4ed;
    box-shadow: -3px 3px 6px 5px #89899329;
    opacity: 1;
    display: none;
}
#slidebox #serivios_lc{
    font-size: .9rem;
    margin: 0;
}
#slidebox a.close {
    background: transparent url(/static/img/close.gif?t=1415028564343) no-repeat top left;
    width: 13px;
    height: 13px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    right: 10px;
    color: transparent;
    opacity: .3;
}

#slidebox .hs-cta-img-asesor {
    border-width: 0;
    width: 70px;
}
.inline-flex-important {
    display: inline-flex !important;
}
#slidebox .texto-box {
    font-size: .9rem;
    line-height: 1.3;
    font-weight: 500;
    color: #050c27;
    padding-bottom: 5px !important;
}
.btn-default {
    font-family: Rubik-SemiBold;
    font-size: .89rem !important;
}

#slidebox .title-box {
    font-size: 1.2rem;
    line-height: 1.3;
}
.sendtext img {
        padding: .2rem 0.5rem;
}
.img-whatsapp{
    right: 0;
    width: 25px;
}
.btn-buscar{
    height: 40px;
}
.icon-slidebox-tooltip {
    position: absolute;
    bottom: 0px;
    background: #fff;
    color: #080C38;
    padding: 4px 12px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    right: 70px;
    width: 145px;
    height: 52px;
    font-size: 0.85rem;
}
.icon-slidebox-tooltip:after {
    content: "";
    display: block;
    position: absolute;
    top: 16px;
    right: -6px;
    border: 8px solid transparent;
    border-width: 6px 0 6px 8px;
    border-left-color: #fff;
}
.icon-slidebox-tooltip{

}

@keyframes slide-up {
    from {
        height: 1px;
    }
    to {
        height: 290px;
    }
}
@media (max-width: 991px) {

}
@media (max-width: 768px) {
    @keyframes slide-up {
        from {
            height: 95px;
        }
        to {
            height: 95px;
        }
    }
}

@media (max-width: 767px) {

    #slidebox .title-box {
        font-size: 1.1rem;
        line-height: 1.3;
    }
    #slidebox .hs-cta-img-asesor {
        border-width: 0;
        width: 60px;
    }
    .btn-buscar {
        height: 40px;
    }
}

@media (max-width: 576px) {
    @keyframes slide-up {
        from {
            height: 80px;
        }
        to {
            height: 80px;
        }
    }
    #slidebox .btn-default.btn-buscar {
        font-size: 12px !important;
    }

    #slidebox .hs-cta-img-asesor {
        border-width: 0;
        width: 50px;
    }
    #slidebox .btn-default.btn-buscar {
        font-size: .9rem !important;
    }
    #slidebox {
        height: 330px;
        max-width: 350px;
        animation: none;

    }
     .icon-slidebox{
            position: fixed;
            bottom: 20px;
            right: 5px;
            width: 60px;
            height: 60px;
            background-color: #FFFFFF;
            border-radius: 50%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
            cursor: pointer;
            transition: transform 0.3s ease-in-out;
            z-index: 10;
        }
    #slidebox.show-box{
         display: none;
    }

}

@media (max-width: 480px) {

    #slidebox .title-box {
        font-size: 0.95rem;
        text-align: left;
        padding-left: 10px !important;
    }
}

@media (max-width: 360px) {
    #slidebox .hs-cta-img-asesor {
        width: 50px;
    }

    @keyframes slide-up {
        from {
            height: 70px;
        }
        to {
            height: 70px;
        }
    }

}